import bugsnagClient from 'bugsnag'
import { assert } from '../../lib/util'

export function sharingInit(_window: Window) {
  $('[data-share=facebook]').click(function(this: any) {
    if (event) { event.preventDefault() }
    fbShare.call(this).catch((ex) => bugsnagClient.notify(ex))
  })
  $('[data-share=twitter]').click(function(this: any) {
    if (event) { event.preventDefault() }
    twitterShare.call(this).catch((ex) => bugsnagClient.notify(ex))
  })
  $('[data-share=copy]').click(function(this: any) {
    if (event) { event.preventDefault() }
    copyLink.call(this).catch((ex) => bugsnagClient.notify(ex))
  })

  $('#breadcrumb-share').on('hide.bs.dropdown', (e) => {
    if (event && event.target) {
      if ($('#breadcrumb-share-dropdown').toArray().find((elem) =>
        $.contains(elem, event?.target as Element))) {
        // don't hide the dropdown
        e.preventDefault()
        return
      }
    }
  })
}

export async function copyLink(this: HTMLAnchorElement, href?: string) {
  if (event) { event.preventDefault() }

  href = href || getCanonical()
  try {
    assert(href)
    await navigator.clipboard.writeText(href)
    $(this).find('span').text('Link Copied')
  } catch (ex) {
    console.error(ex)
    $(this).find('span').text('Could not copy link!')
  }
}

export async function twitterShare(href?: string) {
  href = href || getCanonical()

  const url = `https://twitter.com/intent/tweet?url=${href}&via=WatermarkChurch`
  window.open(url, 'popup', 'width=600,height=600')
}

export async function fbShare(href?: string): Promise<void> {
  href = href || getCanonical()

  const url = `https://www.facebook.com/sharer/sharer.php?u=${href}`
  window.open(url, 'popup', 'width=600,height=600')
}

function getCanonical(): string | undefined {
  const canonical = document.querySelector('link[rel=\'canonical\']')
  if (canonical) {
    return canonical.getAttribute('href') || undefined
  }
}
