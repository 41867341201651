// Global utilities to be included on page
// This file gets imported by `packs/application.js`
//
// This is a manifest file that'll be compiled into the application pack, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
import * as $ from 'jquery'
import 'popper.js'
import 'bootstrap'

import { toggleVisibleByCss } from '../lib/showHide'
import { autoplayInit } from './autoplay'
import emailCapture from './email-capture'
import previewInit from './preview'
import sectionMenuScroll from './section-menu-scroll'
import { viewportInit } from './viewport'

// Porch site functions
import { scrollReadingProgressInit } from './porch/scroll-reading-progress'
import { scrollRotateInit } from './porch/scroll-rotate'
import { sharingInit } from './porch/sharing'
import { codeActionInit } from './code-action'

$(document).ready(() => {
  // JS needed for functionality
  sectionMenuScroll()
  previewInit()
  autoplayInit()
  viewportInit()
  emailCapture()
  scrollRotateInit()
  scrollReadingProgressInit()
  sharingInit(window)
  codeActionInit()

  // https://stackoverflow.com/a/13170350/2192243
  $(document.body).on('click', '[data-href]', function() {
    const href = $(this).data('href')
    if (href && href.length > 0) {
      window.location = href
    }
  })

  // Top nav dropdown headers which are themselves a link
  //  1st click - open the dropdown.
  //  2nd click - follow the link.
  $('a[href][data-toggle="dropdown"]').on('click', function() {
    if ($(this).attr('aria-expanded') == 'true') {
      const href = $(this).attr('href')
      if (href && href.length > 0) {
        window.location = href as any
      }
    }
  })

  // https://getbootstrap.com/docs/4.3/components/tooltips/
  if ($.prototype.tooltip) {
    $('[data-toggle="tooltip"]').tooltip()
  }

  // show/hide tabs by day of week
  toggleVisibleByCss(`[data-weekday="${new Date().getDay()}"]`, '[data-weekday]')
})
