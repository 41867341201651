
export function viewportInit() {
  $(window).resize(onResize)
  onResize()
}

function onResize() {
  $('.formatted-content p .btn ~ .btn').each((i, elem) => {
    checkWrap(elem)
  })
}

function checkWrap(elem: HTMLElement) {
  const prevButton = $(elem).prev()
  // This will work to catch our current use case - if there's a case that ends
  // up breaking this we can revisit the logic.
  // Add the .wrapped class if the item's offset is greater than it's neighbor's
  // offset + height, which indicates it's on a new line.
  if (prevButton.length > 0 &&
      elem.offsetTop >= (prevButton[0].offsetTop + prevButton[0].offsetHeight)) {
    $(elem).addClass('wrapped')
  } else {
    $(elem).removeClass('wrapped')
  }
}
