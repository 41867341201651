import bugsnagClient from 'bugsnag'

export function codeActionInit() {
  $(document.body).on('click', '[data-code-action]', function(evt) {
    const action = $(this).data('code-action')

    switch(action) {
    case 'Open Print Dialog':
      window.print()
      evt.preventDefault()
      break
    default:
      bugsnagClient.notify(`Unknown Code Action: '${action}'`)
      return
    }
  })
}