/**
 * A small utility to rotate an element around its center on scroll.
 *
 * @param {HTMLElement} element - The element to rotate
 * @see {@link https://dev.to/foundsiders/frontend-shorts-easily-rotate-the-element-on-scroll-with-javascript-1g4p}
 */
export function scrollRotate(element: HTMLElement) {
  element.style.transform = `rotate(${window.pageYOffset / 8}deg)`
}

export function scrollRotateInit() {
  $(document).on('scroll', () => {
    const elements = $('[data-scroll-rotate]')
    elements.each((_, element) => scrollRotate(element))
  })
}
