import * as $ from 'jquery'

export default function(
  $element: JQuery<HTMLElement>,
  buffer: number,
  container: JQuery<Window> | JQuery<Document> | JQuery<HTMLElement> = $(window),
): boolean {
  if (!$element || !$element.offset()) {
    return false
  }

  const scrollTop = container.scrollTop()
  const offset = $element.offset()
  const height = $element.height()
  if (scrollTop == undefined || offset == undefined || height == undefined) {
    return false
  }

  const relativeProgress = (scrollTop - offset.top + buffer)

  return 0 <= relativeProgress && relativeProgress < height + buffer
}
