/**
 * A small utility to rotate an element around its center on scroll.
 *
 * @param {HTMLElement} element - The element to rotate
 * @see {@link https://dev.to/foundsiders/frontend-shorts-easily-rotate-the-element-on-scroll-with-javascript-1g4p}
 */
export function scrollReadingProgressInit() {
  $(document).on('scroll', () => {
    const scrollTop = window.scrollY
    const docHeight = document.body.offsetHeight
    const winHeight = window.innerHeight
    const scrollPercent = scrollTop / (docHeight - winHeight)
    const rounded = Math.round(scrollPercent * 100)
    const degrees = scrollPercent * 360
    const progressMarkerTopOffset = $('.progress--marker').offset()?.top ?? 0
    const documentScrollTop = $(document).scrollTop() ?? 0

    $('.progress--background')
      .css(
        'background',
        'conic-gradient(var(--progress-fg-color) ' + degrees + 'deg, var(--progress-bg-color) ' + degrees + 'deg)',
      )
    $('.progress--pb')
      .css(
        'background',
        'linear-gradient(to right, var(--progress-fg-color) ' + rounded + ', var(--progress-bg-color) ' + rounded + ')',
      )

    if (documentScrollTop > (progressMarkerTopOffset - 125)) {
      $('.progress--sticky').addClass('progress--fixed')
    } else {
      $('.progress--sticky').removeClass('progress--fixed')
    }
  })

  $('.progress--jump-to-top').on('click', () => {
    $('html, body').animate({ scrollTop: 0 }, 'fast')
    return false
  })
}
