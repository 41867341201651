
export function toggleVisibleByCss(match: string, nomatch: string) {
  $(nomatch).each(function() {
    const $elem = $(this)
    if ($elem.data('toggle') == 'tab') {
      // bootstrap tab - don't hide
      return
    }

    $elem.removeClass('active').removeClass('show')
  })
  $(match).each(function() {
    const $elem = $(this)
    if ($elem.data('toggle') == 'tab') {
      // handle tabs using the bootstrap JS
      $elem.tab('show')
      return
    }

    $elem.addClass('active').addClass('show')
  })
}
