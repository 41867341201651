export default function() {
  $(() => {
    $('[data-email-capture]').each((_, input) => {
      const $form = $(input)

      function handleResponse(event: any, status: any, xhr: any) {
        let json
        try {
          if (event.detail) {
            json = JSON.parse(event.detail[2].response)
            status = event.detail[1]
          } else {
            json = xhr.responseJSON
          }
        } catch (ex) {
          json = {
            errors: 'An unexpected error occurred!  Please try again later.',
          }
        }

        if (json.errors) {
          $form.append(
            $('<div>').text(json.errors).addClass('errors').delay(5000).fadeOut(),
          )
        }

        if (!json.errors) {
          $form.find('input[type="email"]').val('')
          $form.find('input[type="email"]').replaceWith(`<span id ="success">${json.message}</span>`)
          $form.find('button[type="submit"]').remove()
          $form.find('span').addClass('rounded-0 pl-4 py-3 h-100')
        }
      }

      $form.on('ajax:success', (event, data, status, xhr) => {
        handleResponse(event, status, xhr)
      })

      $form.on('ajax:error', (event, xhr, status) => {
        handleResponse(event, status, xhr)
      })
    })
  })
}
