import * as $ from 'jquery'
import domInView from '../lib/util/dom-in-view'

interface IMenuProps {
  container?: JQuery<Window> | JQuery<Document> | JQuery<HTMLElement>
  menu: JQuery<HTMLElement>
  linkSelector: string
  scrollTimeout?: number
}

class ScrollMenu {
  public menu: JQuery<HTMLElement>

  private container: JQuery<Window> | JQuery<Document> | JQuery<HTMLElement>
  private links: JQuery<HTMLElement>
  private linkSectionMap: { [id: string]: JQuery<HTMLElement> }
  private linkSelector: string
  private scrollEventID: string
  private scrollTimeout: number
  private timeoutRef?: number

  constructor(props: IMenuProps) {
    this.container = props.container || $(window)
    this.menu = props.menu
    this.linkSelector = props.linkSelector
    this.links = $(this.linkSelector, this.menu)
    this.scrollTimeout = props.scrollTimeout || 250
    this.scrollEventID = 'scroll.Menu_' + Math.floor(Math.random() * 100000)

    this.linkSectionMap = this.links.toArray().reduce((map: any, link: HTMLElement) => {
      const href = link.getAttribute('href')
      if (href) {
        map[href] = $(href)
      }

      return map
    }, {})

    this.handleMenuEvent = this.handleMenuEvent.bind(this)
    this.selectLink = this.selectLink.bind(this)
  }

  public bind() {
    this.menu.on('click', this.linkSelector, (evt) => {
      this.selectLink($(evt.currentTarget))
    })

    this.container.on(this.scrollEventID, () => {
      if (this.timeoutRef) { return }

      this.timeoutRef = setTimeout(this.handleMenuEvent, this.scrollTimeout)
    })
  }

  public unbind() {
    this.menu.off('click', this.linkSelector)
    this.container.off(this.scrollEventID as any)
  }

  private clearActive() {
    this.links.parent('li').removeClass('active')
  }

  private getCurrent(): HTMLElement | undefined {
    return this.links.toArray().find((el) => {
      const href = el.getAttribute('href')
      const height = this.menu.height()
      if (!href || !height) { return }

      return domInView(
        this.linkSectionMap[href], height, this.container,
      )
    })
  }

  private handleMenuEvent() {
    const currentLink = this.getCurrent()

    if (domInView(this.menu, 0) && currentLink) {
      this.selectLink($(currentLink))
    } else {
      this.links.parent('li').removeClass('active')
    }

    this.timeoutRef = undefined
  }

  private selectLink(link: JQuery<HTMLElement>) {
    if (link.parent('li').hasClass('active')) { return }

    this.clearActive()
    link.parent('li').addClass('active')
  }
}

export default function() {
  $(() => {
    const menus: ScrollMenu[] = []

    $('.section-menu').each((_, menu) => {
      const scrollMenu = new ScrollMenu({
        menu: $(menu),
        linkSelector: 'ul.nav a.nav-link',
      })

      scrollMenu.bind()

      menus.push(scrollMenu)
    })
  })
}
